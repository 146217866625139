// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'trix';
import '@rails/actiontext';

import InputMask from 'inputmask';
import {Datepicker, DateRangePicker} from 'flowbite-datepicker';
import 'flowbite-datepicker/dist/css/datepicker.css';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import '../images/logo.png';
import '../images/logo-small.png';
import '../stylesheets/application';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import '../components';
import './admin';

document.addEventListener(
  'turbolinks:load',
  () => {
    document
      .querySelectorAll<HTMLElement>('[data-datepicker]')
      .forEach((el) => {
        new Datepicker(el, {
          format: 'yyyy-mm-dd',
        });
      });

    document
      .querySelectorAll<HTMLElement>('[data-daterangepicker]')
      .forEach((el) => {
        new DateRangePicker(el, {
          format: 'yyyy-mm-dd',
        });
      });

    document
      .querySelectorAll<HTMLElement>('[data-currency-mask]')
      .forEach((el) => {
        InputMask({alias: 'currency'}).mask(el);
      });

    const countryCode = document.querySelector<HTMLInputElement>(
      '[name=practice_country_code]',
    );
    if (countryCode !== null && countryCode.value !== '') {
      let options: intlTelInput.Options = {
        initialCountry: countryCode.value,
        onlyCountries: [countryCode.value],
        allowDropdown: false,
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.min.js',
      };
      if (countryCode.value === 'AU') {
        options.nationalMode = false;
        options.formatOnDisplay = true;
      }

      document.querySelectorAll('[data-phone-input]').forEach((el) => {
        intlTelInput(el, options);
      });
    }
  },
  false,
);
