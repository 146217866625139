document.addEventListener('turbolinks:load', () => {
  document
    .querySelectorAll<HTMLFormElement>('.coupon-form')
    .forEach((el) => {});
});

import {Application, Controller as BaseController} from 'stimulus';

class CouponFormController extends BaseController {
  static targets = ['duration', 'durationMonths'];

  declare durationTarget: HTMLSelectElement;
  declare durationMonthsTarget: HTMLElement;

  connect() {
    this.durationTarget.addEventListener('change', (event) => {
      if ((event.target as HTMLSelectElement).value === 'repeating') {
        this.durationMonthsTarget.classList.remove('hidden');
      } else {
        this.durationMonthsTarget.classList.add('hidden');
      }
    });

    if (this.durationTarget.value === 'repeating') {
      this.durationMonthsTarget.classList.remove('hidden');
    }
  }
}

const application = Application.start();
application.register('couponForm', CouponFormController);
