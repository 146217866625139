import {loadStripe, Stripe} from '@stripe/stripe-js';

document.addEventListener(
  'turbolinks:load',
  async () => {
    if (process.env.STRIPE_PUBLISHABLE_KEY === undefined) {
      throw 'STRIPE_PUBLISHABLE_KEY not found';
    }

    const accountFieldEl = document.querySelector(
      '[data-account-field]',
    ) as HTMLInputElement;

    if (accountFieldEl === null) {
      return;
    }

    const demoFieldEl = document.querySelector(
      '[data-demo-field]',
    ) as HTMLInputElement;

    const isDemo = demoFieldEl.value === 'true';

    const stripe = (await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY, {
      stripeAccount: accountFieldEl.value,
    })) as Stripe;

    document.querySelectorAll('[data-membership-fields]').forEach((el) => {
      const formEl = el as HTMLFormElement;
      const tokenFieldEl = el.querySelector(
        '[data-token-field]',
      ) as HTMLInputElement;
      const stripeElementsEl = el.querySelector(
        '[data-stripe-elements]',
      ) as HTMLElement;
      const nameFieldEl = el.querySelector(
        '[data-cardholder-name]',
      ) as HTMLInputElement;

      const cardElement = stripe.elements().create('card');

      const submitListener = async (event) => {
        if (!isDemo) {
          event.preventDefault();
          try {
            const response = await stripe.createToken(cardElement, {
              name: nameFieldEl.value,
            });
            if (response.error !== undefined) {
              return;
            }

            tokenFieldEl.value = response.token?.id;
            formEl.submit();
          } catch (e) {
            console.log(e);
          }
        }
      };

      const showCardElement = () => {
        formEl.addEventListener('submit', submitListener, false);
        cardElement.mount(stripeElementsEl);
        el.querySelectorAll('[data-payment-field]').forEach((e) =>
          e.classList.remove('hidden'),
        );
      };

      const hideCardElement = () => {
        cardElement.unmount();
        formEl.removeEventListener('submit', submitListener, false);
        el.querySelectorAll('[data-payment-field]').forEach((e) =>
          e.classList.add('hidden'),
        );
      };

      const cardProvidedEl = el.querySelector<HTMLInputElement>(
        '[name="card_provided"]',
      );
      console.log(cardProvidedEl);
      if (cardProvidedEl !== null) {
        if (cardProvidedEl.checked) {
          showCardElement();
        }

        cardProvidedEl.addEventListener('change', (event) => {
          console.log(event);
          const target = event.target as HTMLInputElement;
          console.log(target.checked);
          if (target.checked) {
            showCardElement();
          } else {
            hideCardElement();
          }
        });
      } else {
        showCardElement();
      }
    });
  },
  false,
);
