import {loadStripe, Stripe, StripeCardElement} from '@stripe/stripe-js';

document.addEventListener(
  'turbolinks:load',
  async () => {
    if (process.env.STRIPE_PUBLISHABLE_KEY === undefined) {
      throw 'STRIPE_PUBLISHABLE_KEY not found';
    }

    const stripe = (await loadStripe(
      process.env.STRIPE_PUBLISHABLE_KEY,
    )) as Stripe;

    document.querySelectorAll('[data-subscription-fields]').forEach((el) => {
      const formEl = el as HTMLFormElement;
      const tokenFieldEl = el.querySelector(
        '[data-token-field]',
      ) as HTMLInputElement;
      const stripeElementsEl = el.querySelector(
        '[data-stripe-elements]',
      ) as HTMLElement;
      const nameFieldEl = el.querySelector(
        '[data-cardholder-name]',
      ) as HTMLInputElement;

      const cardElement = stripe.elements().create('card');
      cardElement.mount(stripeElementsEl);

      formEl.addEventListener(
        'submit',
        async (event) => {
          event.preventDefault();
          try {
            const response = await stripe.createToken(cardElement, {
              name: nameFieldEl.value,
            });
            if (response.error !== undefined) {
              return;
            }

            tokenFieldEl.value = response.token?.id;
            formEl.submit();
          } catch (e) {
            console.log('wat', e);
          }
        },
        false,
      );
    });
  },
  false,
);
