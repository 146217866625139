import {Application} from 'stimulus';
import camelcase from 'camelcase';

const application = Application.start();
const context = require.context(
  '../components',
  true,
  /[_\/]component\.(js|ts)$/,
);

context.keys().forEach((path) => {
  const mod = context(path);

  // Check whether a module has the Controller export defined
  if (!mod.Controller) return;

  // Convert path into a controller identifier:
  //   example/index.js -> example
  //   nav/user_info/index.js -> nav--user-info
  const identifier = camelcase(
    path
      .replace(/^\.\//, '')
      .replace(/[\_]component\.(js|ts)$/, '')
      .replace(/\//g, '--'),
  );

  application.register(identifier, mod.Controller);
});
