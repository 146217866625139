document.addEventListener(
  'turbolinks:load',
  () => {
    if (document.querySelector('[data-plan-selection') === null) {
      return;
    }

    const planData = JSON.parse(
      document.querySelector('#plan-data')?.innerHTML as string,
    );

    const planInputElement = document.querySelector(
      '[data-plan-input-element]',
    ) as HTMLSelectElement;

    const intervalInputElement = document.querySelector(
      '[data-plan-interval-element]',
    ) as HTMLSelectElement;

    const alreadyCollectedInputElement =
      document.querySelector<HTMLInputElement>(
        '[data-already-collected-element]',
      );

    const planDownPaymentElement = document.querySelector<HTMLInputElement>(
      '[data-plan-down-payment-element]',
    );

    const planMonthlyElement = document.querySelector(
      '[data-plan-monthly-element]',
    ) as HTMLInputElement;

    const calculateAmountBilled = () => {
      const planId = planInputElement.value;
      const interval = intervalInputElement.value;

      const plan = planData[planId];
      const downPayment =
        planDownPaymentElement === null ? 0 : plan.down_payment_amount;
      let amount =
        interval === 'monthly'
          ? downPayment + plan.monthly_amount
          : downPayment + plan.monthly_amount * 12;

      if (alreadyCollectedInputElement !== null) {
        const value = parseFloat(alreadyCollectedInputElement.value);
        if (!isNaN(value)) {
          amount -= value;
        }
      }

      (
        document.querySelector('#payment-tip') as HTMLParagraphElement
      ).textContent = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    };

    const setPlanInfoFields = () => {
      const planId = planInputElement.value;
      const plan = planData[planId];

      if (planDownPaymentElement !== null) {
        planDownPaymentElement.value = plan.down_payment_amount;
      }
      planMonthlyElement.value = plan.monthly_amount;
    };

    planInputElement.addEventListener(
      'change',
      () => {
        setPlanInfoFields();
        calculateAmountBilled();
      },
      false,
    );

    intervalInputElement.addEventListener(
      'change',
      () => {
        calculateAmountBilled();
      },
      false,
    );

    if (alreadyCollectedInputElement !== null) {
      alreadyCollectedInputElement.addEventListener(
        'keyup',
        () => {
          calculateAmountBilled();
        },
        false,
      );
    }

    setPlanInfoFields();
    calculateAmountBilled();
  },
  false,
);
