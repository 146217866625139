import {Controller as BaseController} from 'stimulus';

const STATES = {
  US: [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ],
  CA: ['AB', 'BC', 'NB', 'NL', 'NS', 'ON', 'PE', 'SK', 'QC'],
  AU: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
};

type SupportedCountry = 'US' | 'CA' | 'AU';

export class Controller extends BaseController {
  static targets = ['states'];

  static values = {
    defaultState: String,
    defaultCountry: String,
  };

  declare statesTarget: HTMLSelectElement;
  declare defaultStateValue: string;
  declare defaultCountryValue: SupportedCountry;

  connect() {
    this.statesTarget.innerHTML = this.build_html(this.defaultCountryValue);
    this.statesTarget.value = this.defaultStateValue;
  }

  update_states(event: InputEvent) {
    this.statesTarget.innerHTML = this.build_html(
      (event.target as HTMLSelectElement).value as SupportedCountry,
    );
  }

  build_html(state: SupportedCountry) {
    return STATES[state]
      .map((val) => `<option value="${val}">${val}</option>`)
      .join('\n');
  }
}
